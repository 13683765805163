import * as _ from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { z, ZodObject, ZodSchema } from 'zod';
import { useHistory } from 'react-router-dom';

import { useLocation, Router } from 'components/Link';
import { decodeQuery, encodeQuery, InputQueryObj } from 'lib/utils';

export const updateQuery = <Q extends InputQueryObj>(
  updates: NullableValues<Q> | ((prev: unknown) => NullableValues<Q>),
  /** Set to false to create a new item on the history stack (default: true) */
  replace = true,
  /** Set to true to prevent page data from refreshing (see app/javascript/lib/pageData.tsx) (default: false) */
  shallow = false,
) => {
  const loc = window.location;

  const prevQuery = decodeQuery(loc.search);
  const newQuery = _.isFunction(updates)
    ? updates(prevQuery)
    : {
        ...prevQuery,
        ...updates,
      };

  Router[replace ? 'replace' : 'push']({
    hash: loc.hash,
    pathname: loc.pathname,
    search: encodeQuery(newQuery),
    state: shallow ? { shallow: true } : undefined,
  });
};

export const useUrlQuery = <Q extends Record<string, string | undefined>>() => {
  const location = useLocation();
  const query = useMemo(
    () => decodeQuery<Q>(location.search),
    [location.search],
  );

  return [
    query,
    updateQuery as typeof updateQuery<{
      [K in keyof Q]: InputQueryObj[string];
    }>,
  ] as const;
};

type GetRecordValue<T extends Record<string, unknown>> = T[keyof T];

type NullableValues<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] | null | undefined;
};

// subscribe to changes in the url query, but only for the given keys
export const usePartialUrlQuery = (changeKeys: string[]) => {
  const history = useHistory();
  const [query, setQuery] = useState(() =>
    decodeQuery(history.location.search),
  );

  useEffect(() => {
    return history.listen((nextLocation) => {
      setQuery((prevQuery) => {
        const nextQuery = decodeQuery(nextLocation.search);
        if (
          prevQuery.pathname !== nextLocation.pathname ||
          changeKeys.some((key) => prevQuery[key] !== nextQuery[key])
        ) {
          return nextQuery;
        }

        return prevQuery;
      });
    });
  }, [history, changeKeys]);

  return query;
};

/**
 * NOTE: All fields must be optional.
 */
export const useUrlFilters = <
  Schema extends ZodObject<any, any, ZodSchema<GetRecordValue<InputQueryObj>>>,
>(
  zodSchema: Schema,
) => {
  type QType = z.infer<Schema>;

  const changeKeys = useMemo(() => Object.keys(zodSchema.shape), [zodSchema]);

  const rawQuery = usePartialUrlQuery(changeKeys);

  const query = useMemo(() => {
    const decoded = rawQuery;
    const parsed = zodSchema.safeParse(decoded);
    return (parsed.success ? parsed.data : {}) as QType;
  }, [zodSchema, rawQuery]);

  return [
    query,
    updateQuery as typeof updateQuery<NullableValues<QType>>,
  ] as const;
};
