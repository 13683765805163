import { getHost } from 'lib/env';

/** `new URL` can throw, so we wrap it in a try/catch. returns `null` if invalid. */
export const parseUrl = (url: string, base = getHost()) => {
  try {
    const parsed = url ? new URL(url, base) : null;
    // if invalid, pathname will be empty
    if (parsed?.pathname) return parsed;
  } catch {}
  return null;
};

export const isUrl = (val: string) => {
  if (!/^https?:\/\/\w/.test(val)) return false;
  try {
    const url = new URL(val);
    return !!(url.pathname && url.protocol && url.host);
  } catch {}
  return false;
};
